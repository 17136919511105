export const max = (a,b) => {
    return a > b ? a : b;
}

export const min = (a,b) => {
    return a < b ? a : b;
}

export const clamp = (num,low,high) => {
    return (num > high)
        ? high 
        : (num < low)
        ? low
        : num;
}

export const clampLow = (num,low) => {
    return num < low ? low : num;
}

export const clampHigh = (num, high) => {
    return num > high ? high : num;
}