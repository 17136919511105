import React from "react"
import { Link } from "gatsby"
import Footer from "../components/footer"
import Logo from "../components/logo"
import Frame from "../components/frame"
import ScrollFadeIn from "../components/ui/effects/scrollfadein"
import indexStyle from "./style/index.module.css"

export default () => {

	return (
		<Frame nav={false} gutter={false}>
    		<div className={`${indexStyle.root} text-serif`} style={{minHeight: "100vh"}}>
				<div className={`${indexStyle.herobox} text-bold`}>
					<div className={`${indexStyle.hero} text-sans-serif-display`}>
						<h1 className={`${indexStyle.hello}`}>Hello.</h1>
						{<p className={`${indexStyle.ben}`}>My name is Ben.</p>}
					</div>                      
				</div>
				<div style={{height: "4.8em"}} />
				<div className={`${indexStyle.spacing}`}>
					<ScrollFadeIn distance="0.17" offset="0.2">
						<p className={`${indexStyle.blurb} text-serif`}>And I'm interested in making things that help humans interact with machines.</p>
						<Link to="/blog" className={`text-no-deco text-serif ${indexStyle.blurb}`}>Go to the blog</Link>
					</ScrollFadeIn>
				</div>
			</div>
			<Footer/>
		</Frame>
    
	)
}
