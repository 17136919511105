import React, { useState, useRef, useEffect } from "react"
import { max, clamp, clampLow } from "../../../../static/number"

export default (props) => {

    // props.distance: 0 < distance < 1
    //      this is the number that defines how far into view
    //      the box should be when we reach full opacity

    // props.offset: 0 < offset < 1
    //      this is the distance from the bottom of the window where the
    //      transition starts

    // props.strict: boolean
    //      if this is set, the offset will be set to the user defined offset
    //      otherwise, if the top of the fade box is above the bottom of the screen
    //      when scrolled all the way to the top (e.g. boxTop < window.height),
    //      the offset will be set to that value. 

    const thisRef = useRef(null);

    const [thisOpacity, setOpacity] = useState(1);

    useEffect(() => {

        const distance = (props.distance === undefined || props.distance === null) 
            ? 0.5
            : (typeof props.distance === 'string') 
            ? clamp(parseFloat(props.distance) , 0, 1)
            : clamp(props.distance,0,1);

        const offset = ((props.offset === undefined || props.offset === null) 
        ? 0.0
        : (typeof props.offset === 'string') 
        ? clamp(parseFloat(props.offset), 0,1)
        : clamp(props.offset,0,1)) * window.innerHeight;

        const scrollHandler = (e) => {
            const rectTop = thisRef.current.getBoundingClientRect().y;
            const newoffset = props.strict ? offset : max(offset, clampLow(window.innerHeight - (rectTop + window.scrollY), 0));
            const dist = (window.innerHeight - rectTop - newoffset) / (distance * window.innerHeight);
            const out = clamp(dist,0,1);
            setOpacity(out);
        };

        window.addEventListener('scroll', scrollHandler);

        // fire once when the component is mounted
        scrollHandler();

        // and clean up;
        return () => {window.removeEventListener('scroll', scrollHandler)}
    });
    
    const style = props.style ? {...props.style} : {};

    style.opacity = thisOpacity;

    return (
        <div ref={thisRef} style={style} className={props.className}>
            {props.children}
        </div>
    );
}